import React from "react";
import { Layout } from "../layout/Layout";
import Accueil from "../accueil/Accueil";
import Landing from "../landing/Landing";
import Starter from '../starter/Starter';
import Footer from "../footer/Footer";
import Dashboard from "../dashboard/Dashboard";
import '../indexroute';

function Home() {
    return ( 
        <Layout>
            <>
            <Accueil />
            <Landing />
            <Starter />
            <Dashboard />
            <Footer />
            </>
        </Layout>
    );
}

export default Home;