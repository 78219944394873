import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';
import indian from '../pictures/male_india_200.png';
import afghan from '../pictures/male_afghan_200.png';
import pakistan from '../pictures/male_pakistan_200.png';
import bengali from '../pictures/male_bengali_200.png';
import indonesia from '../pictures/male_indonesia_200.png';
import iran from '../pictures/male_iran_200.png';
import turkey from '../pictures/male_turkey_200.png';
import malaysia from '../pictures/male_malaysia_200.png';

import { 
    StarterContainer, 
    //TextTitle, 
    StarterImage, 
    ButtonStart,  
    FormImageScroller,
    TextNormal, 
    FormContentAvatar,
    BackStart 
} from '../StarterStyles';

function StarterFiveMan() {
    return ( 
        <StarterContainer style={FontEcran} id="starterfiveman">
            <FormImageScroller>
                <br/>
                <TextNormal>I prefer members origin</TextNormal>
                <TextNormal>from list bellow</TextNormal>
                <div style={{textAlign:'center', fontWeight:'bolder', fontFamily:'cursive', fontSize:'21px'}}>
                    <label>You can choose only one</label>
                </div>
                <br/>
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="manpreferindia" name="manpreferindia" value="india" />
                        <label htmlFor="manpreferindia"><StarterImage src={indian} alt="logo" />India</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="manpreferpakistan" name="manpreferpakistan" value="pakistan" />
                        <label htmlFor="manpreferpakistan"><StarterImage src={pakistan} alt="logo" />Pakistan</label><br/>
                    </div>
                </FormContentAvatar>
                
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="manpreferbengali" name="manpreferbengali" value="bengali" />
                        <label htmlFor="manpreferbengali"><StarterImage src={bengali} alt="logo" />Bengladesh</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="manpreferafghan" name="manpreferafghan" value="afghan" />
                        <label htmlFor="manpreferafghan"><StarterImage src={afghan} alt="logo" />Afghanistan</label><br/>
                    </div>
                </FormContentAvatar>
                
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="manpreferindonesia" name="manpreferindonesia" value="indonesia" />
                        <label htmlFor="manpreferindonesia"><StarterImage src={indonesia} alt="logo" />Indonesia</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="manpreferiran" name="manpreferiran" value="iran" />
                        <label htmlFor="manpreferiran"><StarterImage src={iran} alt="logo" />Iran</label><br/>
                    </div>
                </FormContentAvatar>
                
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="manpreferturkey" name="manpreferturkey" value="turkey" />
                        <label htmlFor="manpreferturkey"><StarterImage src={turkey} alt="logo" />Turkey</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="manprefermalaysia" name="manprefermalaysia" value="malaysia" />
                        <label htmlFor="manprefermalaysia"><StarterImage src={malaysia} alt="logo" />Malaysia</label><br/>
                    </div>
                </FormContentAvatar>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterfiveman">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartfiveman">Back to start</button>
                </BackStart>
                <div id="modaloutalertstarterfiveman"></div>
                <div id="backdropoutalertstarterfiveman"></div>
            </FormImageScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterFiveMan;