import React from 'react';

import { Container } from './LayoutStyles';

export function Layout({children}){
    return(
        <Container>
            <main>{children}</main>
        </Container>
    );
}