import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';
//import {BsTelephone} from 'react-icons/bs';
//import {FcGoogle} from 'react-icons/fc';

import { 
    StarterContainer, 
    FormScrollerOne,
    TextTitle, 
    TextContent, 
    ButtonStart, 
    //ButtonStartGoogle,
    TermsLabel, 
} from '../StarterStyles';

function StarterOne() {
    return ( 
        <StarterContainer style={FontEcran} id="starterone">
            <FormScrollerOne>
                <br/>
                <TextContent>Pure Rishta UK Only</TextContent>
                <TextTitle>
                    <label>Intelligent Smart Dating</label>
                </TextTitle>
                <TextContent>RealTime Matchmaker</TextContent>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterone">Get Started Now</ButtonStart>
                </div>
                <br/>
                <TermsLabel id='terms'>By signing in, you agree to our Terms</TermsLabel>
                <br/>
            </FormScrollerOne>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterOne;