import React from "react";
import { FooterContainer, FooterBaseContainer, FooterLabel } from './FooterStyles';

function Footer() {
    return ( 
        <FooterContainer id="footer">
            <FooterBaseContainer>
                <FooterLabel>
                    <label id="about">About</label>
                </FooterLabel>
                <FooterLabel>
                    <label>Privacy</label>
                </FooterLabel>
                <FooterLabel>
                    <label>Terms</label>
                </FooterLabel>
                <FooterLabel>
                    <label>Security</label>
                </FooterLabel>
            </FooterBaseContainer>
            <p style={{color:'white'}}>Copyright 2022 &copy; <a style={{color:'white', textDecoration:'none'}} href="https://www.carions.grandbico.com/">Carion's</a></p>
        </FooterContainer>
    );
}

export default Footer;