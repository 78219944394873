import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';
import indian from '../pictures/female_india_200.png';
import afghan from '../pictures/female_afghan_200.png';
import pakistan from '../pictures/female_pakistan_200.png';
import bengali from '../pictures/female_bengali_200.png';
import indonesia from '../pictures/female_indonesia_200.png';
import iran from '../pictures/female_iran_200.png';
import turkey from '../pictures/female_turkey_200.png';
import malaysia from '../pictures/female_malaysia_200.png';

import { 
    StarterContainer, 
    //TextTitle, 
    StarterImage, 
    ButtonStart,  
    FormImageScroller,
    TextNormal, 
    FormContentAvatar, 
    BackStart 
} from '../StarterStyles';

function StarterFive() {
    return ( 
        <StarterContainer style={FontEcran} id="starterfive">
            <FormImageScroller>
                <br/>
                <TextNormal>I prefer members origin</TextNormal>
                <TextNormal>from list bellow</TextNormal>
                <div style={{textAlign:'center', fontWeight:'bolder', fontFamily:'cursive', fontSize:'21px'}}>
                    <label>You can choose only one</label>
                </div>
                <br/>
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="preferindia" name="preferindia" value="india" />
                        <label htmlFor="preferindia"><StarterImage src={indian}  alt="logo" />India</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="preferpakistan" name="preferpakistan" value="pakistan" />
                        <label htmlFor="preferpakistan"><StarterImage src={pakistan}  alt="logo" />Pakistan</label><br/>
                    </div>
                </FormContentAvatar>
                
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="preferbengali" name="preferbengali" value="bengali" />
                        <label htmlFor="preferbengali"><StarterImage src={bengali}  alt="logo" />Bengladesh</label><br/>
                    </div>

                    <div>
                        <input type="checkbox" id="preferafghan" name="preferafghan" value="afghan" />
                        <label htmlFor="preferafghan"><StarterImage src={afghan} alt="logo" />Afghanistan</label><br/>
                    </div>
                </FormContentAvatar>
                
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="preferindonesia" name="preferindonesia" value="indonesia" />
                        <label htmlFor="preferindonesia"><StarterImage src={indonesia} alt="logo" />indonesia</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="preferiran" name="preferiran" value="iran" />
                        <label htmlFor="preferiran"><StarterImage src={iran} alt="logo" />Iran</label><br/>
                    </div>
                </FormContentAvatar>

                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="preferturkey" name="preferturkey" value="turkish" />
                        <label htmlFor="preferturkey"><StarterImage src={turkey} alt="logo" />Turkey</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="prefermalaysia" name="prefermalaysia" value="malaysian" />
                        <label htmlFor="prefermalaysia"><StarterImage src={malaysia} alt="logo" />Malaysia</label><br/>
                    </div>
                </FormContentAvatar>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterfive">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartfive">Back to start</button>
                </BackStart>
                <div id="modaloutalertstarterfive"></div>
                <div id="backdropoutalertstarterfive"></div>
            </FormImageScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterFive;