import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    FormContent, 
    BackStart 
} from '../StarterStyles';

function StarterFour() {
    return ( 
        <StarterContainer style={FontEcran} id="starterfour">
            <FormScroller>
                <br/>
                <TextNormal>I am Seeking</TextNormal>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="seekserious" name="seekserious" value="serious" />
                        <label htmlFor="seekserious">Serious relationship</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="seeklife" name="seeklife" value="life" />
                        <label htmlFor="seeklife">Life partner</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="seekcompanion" name="seekcompanion" value="companion" />
                        <label htmlFor="seekcompanion">Companion</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="seektravel" name="seektravel" value="travel" />
                        <label htmlFor="seektravel">Travel mate</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="seekonline" name="seekonline" value="online" />
                        <label htmlFor="seekonline">Online friendship</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="seekflirting" name="seekflirting" value="flirting" />
                        <label htmlFor="seekflirting">Flirting/talks</label><br/>
                    </div>
                </FormContent>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterfour">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartfour">Back to start</button>
                </BackStart>
                <div id="modaloutalertstarterfour"></div>
                <div id="backdropoutalertstarterfour"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterFour;