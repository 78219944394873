import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1300px;
    width: 100%;
    margin: auto;
    float: left;

    @media screen and (max-width: 640px){
        max-width: 1200px;
        width: 100%;
        float: left;
    }

    @media screen and (max-width: 768px){
        max-width: 1200px;
        width: 100%;
        float: left;
    }
`;