import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';
import travel from '../pictures/travel_200.png';
import nature from '../pictures/nature_200.png';
import sport from '../pictures/sport_200.png';
import beach from '../pictures/beach_200.png';


import { 
    StarterContainer, 
    //TextTitle, 
    StarterImage, 
    ButtonStart,  
    FormImageScroller,
    TextNormal, 
    FormContentAvatar, 
    BackStart 
} from '../StarterStyles';

function StarterSix() {
    return ( 
        <StarterContainer style={FontEcran} id="startersix">
            <FormImageScroller>
                <br/>
                <TextNormal>My lifestyle</TextNormal>
                <div style={{textAlign:'center', fontWeight:'bolder', fontFamily:'cursive', fontSize:'21px'}}>
                    <label>You can choose only one</label>
                </div>
                <br/>
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="lifetravel" name="lifetravel" value="travel" />
                        <label htmlFor="lifetravel"><StarterImage src={travel} alt="logo" />Travel</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="lifenature" name="lifenature" value="nature" />
                        <label htmlFor="lifenature"><StarterImage src={nature} alt="logo" />Nature</label><br/>
                    </div>
                </FormContentAvatar>
                <br/>
                <FormContentAvatar>
                    <div>
                        <input type="checkbox" id="lifesport" name="lifesport" value="sport" />
                        <label htmlFor="lifesport"><StarterImage src={sport} alt="logo" />Sport</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="lifebeach" name="lifebeach" value="beach" />
                        <label htmlFor="lifebeach"><StarterImage src={beach} alt="logo" />Beach</label><br/>
                    </div>
                </FormContentAvatar>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstartersix">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartsix">Back to start</button>
                </BackStart>
                <div id="modaloutalertstartersix"></div>
                <div id="backdropoutalertstartersix"></div>
            </FormImageScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterSix;