import React from "react-dom";
import './dashboard.css';
import './pouchdashboard';
import rishta from './../ifti_3.jpg';

import { 
    DatingContainer,
    FormScrollerDating,
    TextDating,
    WidgetDatingTable,
    WidgetDatingTh,
} from './DatingListStyles';

function DatingList() {
    return ( 
        <DatingContainer style={FontEcran} id="datinglist">
            <FormScrollerDating>
                <br/>
                <TextDating>Your Dating List</TextDating>
                <WidgetDatingTable>
                    <thead>
                        <tr className="widgetdatingTr">
                            <WidgetDatingTh>Date</WidgetDatingTh>
                            <WidgetDatingTh>Look for</WidgetDatingTh>
                            <WidgetDatingTh>From</WidgetDatingTh>
                            <WidgetDatingTh>Status</WidgetDatingTh>
                        </tr>
                    </thead>
                    <tbody id='datingTableTableBody'></tbody>
                </WidgetDatingTable>
            </FormScrollerDating>
        </DatingContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default DatingList;