import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    //FormContent, 
    BackStart, 
    LargeInput,
    LargeSelect,   
} from '../StarterStyles';

function StarterNine() {
    return ( 
        <StarterContainer style={FontEcran} id="starternine">
            <FormScroller>
                <br/>
                <TextNormal>Your're almost done!</TextNormal>
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <div>
                        <LargeSelect id="origine">
                            <option value=''>Origin Country you're from</option>
                            <option value='india'>India</option>
                            <option value='pakistan'>Pakistan</option>
                            <option value='bengali'>Bengladesh</option>
                            <option value='afghan'>Afghanistan</option>
                            <option value='indonesia'>Indonesia</option>
                            <option value='iran'>Iran</option>
                            <option value='turkey'>Turkey</option>
                            <option value='malaysia'>Malaysia</option>
                        </LargeSelect>
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" id="username" placeholder='Username' />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" id="password" placeholder="Password" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" id="country" placeholder="Country you currently live" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="text" id="location" placeholder="City you currently live" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="email" id="emailaddress" placeholder="Email" />
                    </div>
                    <br/>
                    <div>
                        <LargeInput type="tel" maxLength="11" id="telephone" placeholder="Confirm your Phone number(11digits)" />
                    </div>
                </div>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarternine">Login Now</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartnine">Back to start</button>
                </BackStart>
                <div id="modaloutstarternine"></div>
                <div id="backdropoutstarternine"></div>

                <div id="modaloutalertstarternine"></div>
                <div id="backdropoutalertstarternine"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterNine;