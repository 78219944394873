import React from "react";
import './dashboard.css';
import './pouchdashboard';

import DatingList from "./DatingList";
import DatingNotification from "./DatingNotification";

function Dashboard() {
    return ( 
        <div id="dashboard">
            <DatingList />
            <DatingNotification />
        </div>
    );
}

export default Dashboard;