import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    FormScrollerOne,
    TextLogin, 
    LargeInput,
    BackStart,
    ButtonStart,  
} from '../StarterStyles';

function StarterLogin() {
    return ( 
        <StarterContainer style={FontEcran} id="starterlogin">
            <FormScrollerOne>
                <br/>
                <TextLogin>Phone number or Username</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type="tel" maxLength="11" id="loginphonenumber" placeholder='UK phone number(11 digits)' />
                </div>
                <br/>
                <TextLogin>Password</TextLogin>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type="text" id="loginpassword" placeholder='Password' />
                </div>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterlogin">Login</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartlogin">Back to start</button>
                </BackStart>
                <div id="modaloutstarterlogin"></div>
                <div id="backdropoutstarterlogin"></div>

                <div id="modaloutalertstarterlogin"></div>
                <div id="backdropoutalertstarterlogin"></div>
            </FormScrollerOne>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterLogin;