import styled from "styled-components";

export const FooterContainer = styled.div`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 0, 128);
    color: white;
    text-align: center;
`;

export const FooterBaseContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FooterLabel = styled.div`
    font-size: 0.9rem;
    margin-left: 250px;

    @media screen and (max-width: 640px){
        margin-left: 30px;
    }

    @media screen and (max-width: 768px){
        margin-left: 40px;
    }
`;