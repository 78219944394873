import React from "react";
import './starter.css';
import './pouchstarter';

import StarterOne from "./stepstarter/StarterOne";
import StarterPhone from "./stepstarter/StarterPhone";
import StarterLogin from "./stepstarter/StarterLogin";
import StarterTwo from "./stepstarter/StarterTwo";
import StarterThree from "./stepstarter/StarterThree";
import StarterFour from "./stepstarter/StarterFour";
import StarterFive from "./stepstarter/StarterFive";
import StarterFiveMan from "./stepstarter/StarterFiveMan";
import StarterSix from "./stepstarter/StarterSix";
import StarterSeven from "./stepstarter/StarterSeven";
import StarterEight from "./stepstarter/StarterEight";
import StarterNine from "./stepstarter/StarterNine";

function Starter() {
    return ( 
        <div id="starter">
            <StarterOne />
            <StarterPhone />
            <StarterLogin />
            <StarterTwo />
            <StarterThree />
            <StarterFour />
            <StarterFive />
            <StarterFiveMan />
            <StarterSix />
            <StarterSeven />
            <StarterEight />
            <StarterNine />
        </div>
    );
}

export default Starter;