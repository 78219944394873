import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    FormContent, 
    BackStart 
} from '../StarterStyles';

function StarterThree() {
    return ( 
        <StarterContainer style={FontEcran} id="starterthree">
            <FormScroller>
                <br/>
                <TextNormal>My age is</TextNormal>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="myage24" name="myage24" value="age24" />
                        <label htmlFor="myage24">&#62;24</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="myage25" name="myage25" value="age25" />
                        <label htmlFor="myage25">25 - 34</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="myage35" name="myage35" value="age35" />
                        <label htmlFor="myage35">35 - 44</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="myage45" name="myage45" value="age45" />
                        <label htmlFor="myage45">45 -54</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="myage55" name="myage55" value="age55" />
                        <label htmlFor="myage55">55 - 64</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="myage65" name="myage65" value="age65" />
                        <label htmlFor="myage65">&#60;65</label><br/>
                    </div>
                </FormContent>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterthree">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartthree">Back to start</button>
                </BackStart>
                <div id="modaloutalertstarterthree"></div>
                <div id="backdropoutalertstarterthree"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterThree;