/* eslint-disable no-redeclare */
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';

window.addEventListener('load', () => {
    document.getElementById('getstarterone').addEventListener('click', getstarteroneChange);
    async function getstarteroneChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'block';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    };

    document.getElementById('getstarterphone').addEventListener('click', getstarteronephoneChange);
    async function getstarteronephoneChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertstarterphone";
        var elementDropId = "backdropoutalertstarterphone";
        var buttonHandler = "ouiHandlerAlertStarterPhoneBtn";

        //set up connection parameters
        var hostnameStr = '127.0.0.1';
        var portnumberStr = '5984';
        var usernameStr = 'kouaskon';
        var passwordStr = 'Mandidjouman1';
        var databaseStr = 'rishta';

        //set fields values
        var prefixeStr = '0044';
        var phonenumberStr = document.getElementById('phonenumber').value;
        var documentStr = 'phone';
        var dateStr = getCurrentDate();
        if(! phonenumberStr){
            var pTextechooseone = "Enter your UK phone number(11 digits)";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            var phonenumber = /^\d{11}$/;
            if(!phonenumberStr.match(phonenumber)){
                var pTexteinvalidnumber = "Not a valid phone number";
                alertModal(elementOutId,elementDropId,pTexteinvalidnumber,buttonHandler);
            }else{
                //var docsMatch = [];
                var docsCheckNumber = [];
                try {
                    const db = new PouchDB(databaseStr);
                    const remote = new PouchDB("http://"+usernameStr +":"+passwordStr +"@"+hostnameStr +":"+portnumberStr +"/"+databaseStr);
                    PouchDB.plugin(PouchFind);
                
                    function syncbase() {
                        var opts = {
                            live: true,
                            retry: true,
                            continuous: true,
                            attachments: true,
                        };
                        db.sync(remote, opts);
                    }
                    if (remote) {
                        syncbase();
                    }

                    let result = await db.createIndex({
                        index: {
                            fields: [
                                "prefixe", 
                                "telephone", 
                            ],
                        },
                    });
                    result = await db.find({
                        selector: {
                            prefixe: prefixeStr,
                            telephone: phonenumberStr,
                        },
                    });
                    docsCheckNumber = result.docs;
                    var resultatDocsCheckNumber = docsCheckNumber.length;
                    if (resultatDocsCheckNumber === 0){
                        var divClearModal = document.getElementById("modaloutstarterphone");
                        let divModal = document.createElement("div");
                        divModal.innerHTML = 
                        `<div class="modal">
                            <p>Is this number already been used before?</p>
                            <button class="btn btn--alt" id="ouiHandlerExistBtn">Yes</button>
                            <button class="btn" id="nonHandlerExistsBtn">No</button>
                        </div>`;
                        divClearModal.appendChild(divModal);

                        var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                        let divBack = document.createElement("div");
                        divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseExistsBtn"></div>`;
                        divClearBackdrop.appendChild(divBack);

                        //If Yes Clicked
                        document.getElementById("ouiHandlerExistBtn").addEventListener("click", ouiHandlerExistBtnChange);
                        async function ouiHandlerExistBtnChange(e){
                            e.preventDefault();

                            var divClearModal = document.getElementById("modaloutstarterphone");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            var pTexteincorrectnumber = "Enter your valid phone number";
                            alertModal(elementOutId,elementDropId,pTexteincorrectnumber,buttonHandler);
                        }

                        //If No Clicked
                        document.getElementById("nonHandlerExistsBtn").addEventListener("click", nonHandlerExistsBtnChange);
                        async function nonHandlerExistsBtnChange(e){
                            e.preventDefault();

                            var divClearModal = document.getElementById("modaloutstarterphone");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }

                            var divClearModal = document.getElementById("modaloutstarterphone");
                            let divModal = document.createElement("div");
                            divModal.innerHTML = `
                            <div class="modal">
                                <p>Wish to Use it?</p>
                                <button class="btn btn--alt" id="ouiHandlerUseBtn">Yes</button>
                                <button class="btn" id="nonHandlerUseBtn">No</button>
                            </div>`;
                            divClearModal.appendChild(divModal);

                            var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                            let divBack = document.createElement("div");
                            divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseUseBtn"></div>`;
                            divClearBackdrop.appendChild(divBack);

                            //If Yes Use Clicked
                            document.getElementById("ouiHandlerUseBtn").addEventListener("click", ouiHandlerUseBtnChange);
                            async function ouiHandlerUseBtnChange(e){
                                e.preventDefault();

                                try {
                                    //Create the document _id and _rev.
                                    var now = Date.now();
                                    //new database id
                                    var id = documentStr+"_" + now;
                                    // Add the document to the database.
                                    var response = await db.put({
                                        _id: id,
                                        prefixe: prefixeStr,
                                        telephone: phonenumberStr,
                                        date: dateStr,
                                    });
                                    // check if document inserted
                                    var doc = await db.get(response.id);
                                    if (doc !== ""){
                                        var divClearModal = document.getElementById("modaloutstarterphone");
                                        while (divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                                        while (divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }

                                        var pTextesucces = "Sign in successful";
                                        alertModal(elementOutId,elementDropId,pTextesucces,buttonHandler);

                                        document.getElementById('landing').style.display = 'none';
                                        document.getElementById('starter').style.display = 'block';
                                        document.getElementById('starterone').style.display = 'none';
                                        document.getElementById('starterphone').style.display = 'none';
                                        document.getElementById('starterlogin').style.display = 'none';
                                        document.getElementById('startertwo').style.display = 'block';
                                        document.getElementById('starterthree').style.display = 'none';
                                        document.getElementById('starterfour').style.display = 'none';
                                        document.getElementById('starterfive').style.display = 'none';
                                        document.getElementById('starterfiveman').style.display = 'none';
                                        document.getElementById('startersix').style.display = 'none';
                                        document.getElementById('starterseven').style.display = 'none';
                                        document.getElementById('startereight').style.display = 'none';
                                        document.getElementById('starternine').style.display = 'none';
                                    }else{
                                        var divClearModal = document.getElementById("modaloutstarterphone");
                                        while (divClearModal.hasChildNodes()) {
                                            divClearModal.removeChild(divClearModal.childNodes[0]);
                                        }

                                        var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                                        while (divClearBackdrop.hasChildNodes()) {
                                            divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                        }

                                        var pTexteerreurconnection = "An Error has Occurred";
                                        alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                    }
                                } catch (error) {
                                    var pTexteerreurconnection = "An Error has Occurred";
                                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                                }
                            }

                            //If No Use Clicked
                            document.getElementById("nonHandlerUseBtn").addEventListener("click", nonHandlerUseBtnChange);
                            async function nonHandlerUseBtnChange(e){
                                e.preventDefault();

                                var divClearModal = document.getElementById("modaloutstarterphone");
                                while (divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                                while (divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }
                            }

                            //If backdrop Clicked
                            document.getElementById("backdropHandlerCloseUseBtn").addEventListener("click", backdropHandlerCloseUseBtnChange);
                            async function backdropHandlerCloseUseBtnChange(e) {
                                e.preventDefault();

                                //code here

                                var divClearModal = document.getElementById("modaloutstarterphone");
                                while (divClearModal.hasChildNodes()) {
                                    divClearModal.removeChild(divClearModal.childNodes[0]);
                                }

                                var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                                while (divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                                }
                            }
                        }

                        //If backdrop Clicked
                        document.getElementById("backdropHandlerCloseExistsBtn").addEventListener("click", backdropHandlerCloseExistsBtnChange);
                        async function backdropHandlerCloseExistsBtnChange(e) {
                            e.preventDefault();

                            //code here

                            var divClearModal = document.getElementById("modaloutstarterphone");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarterphone");
                                while (divClearBackdrop.hasChildNodes()) {
                                    divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]
                                );
                            }
                        }
                    }else{
                        document.getElementById('landing').style.display = 'none';
                        document.getElementById('starter').style.display = 'block';
                        document.getElementById('starterone').style.display = 'none';
                        document.getElementById('starterphone').style.display = 'none';
                        document.getElementById('starterlogin').style.display = 'none';
                        document.getElementById('startertwo').style.display = 'block';
                        document.getElementById('starterthree').style.display = 'none';
                        document.getElementById('starterfour').style.display = 'none';
                        document.getElementById('starterfive').style.display = 'none';
                        document.getElementById('starterfiveman').style.display = 'none';
                        document.getElementById('startersix').style.display = 'none';
                        document.getElementById('starterseven').style.display = 'none';
                        document.getElementById('startereight').style.display = 'none';
                        document.getElementById('starternine').style.display = 'none';
                    }
                } catch (error) {
                    var pTexteerreurconnection = "An Error has Occurred";
                    alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                }
            }
        }
    };

    document.getElementById('login').addEventListener('click', loginChange);
    async function loginChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'block';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    document.getElementById('getstarterlogin').addEventListener('click', getstarterloginChange);
    async function getstarterloginChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertstarterlogin";
        var elementDropId = "backdropoutalertstarterlogin";
        var buttonHandler = "ouiHandlerAlertStarterLoginBtn";

        //set up connection parameters
        var hostnameStr = '127.0.0.1';
        var portnumberStr = '5984';
        var usernameStr = 'kouaskon';
        var passwordStr = 'Mandidjouman1';
        var databaseStr = 'rishta';

        //set fields values
        var loginphonenumberStr = document.getElementById('loginphonenumber').value;
        var loginpasswordStr = document.getElementById('loginpassword').value;
        if(!loginphonenumberStr){
            var pTextechooseusername = "Enter phone number or Username";
            alertModal(elementOutId, elementDropId, pTextechooseusername, buttonHandler);
        }else if(!loginpasswordStr){
            var pTextechoosepassword = "Enter Password";
            alertModal(elementOutId, elementDropId, pTextechoosepassword, buttonHandler);
        }else{
            var docsCheckPhone = [];
            var docsCheckUsername = [];
            try {
                const db = new PouchDB(databaseStr);
                const remote = new PouchDB("http://"+usernameStr +":"+passwordStr +"@"+hostnameStr +":"+portnumberStr +"/"+databaseStr, {stip_setup:true});
                PouchDB.plugin(PouchFind);
                function syncbase() {
                    var opts = {
                        live: true,
                        retry: true,
                        continuous: true,
                        attachments: true,
                    };
                    db.sync(remote, opts);
                }
                if (remote) {
                    syncbase();
                }

                let resultPhone = await db.createIndex({
                    index: {
                        fields: [ 
                            "phone", 
                            "password",
                        ],
                    },
                });
                resultPhone = await db.find({
                    selector: {
                        phone: loginphonenumberStr,
                        password: loginpasswordStr,
                    },
                });
                docsCheckPhone = resultPhone.docs;
                var resultatDocsCheckPhone = docsCheckPhone.length;
                if(resultatDocsCheckPhone === 0){
                    let resultUsername = await db.createIndex({
                        index: {
                            fields: [ 
                                "username", 
                                "password",
                            ],
                        },
                    });
                    resultUsername = await db.find({
                        selector: {
                            username: loginphonenumberStr,
                            password: loginpasswordStr,
                        },
                    });
                    docsCheckUsername = resultUsername.docs;
                    var resultatDocsCheckUsername = docsCheckUsername.length;
                    if(resultatDocsCheckUsername === 0){
                        document.getElementById('loginphonenumber').value = '';
                        var pTexteincorrectconnection = "Invalid Login details.";
                        alertModal(elementOutId,elementDropId,pTexteincorrectconnection,buttonHandler);
                    }else{
                        updateDatingListTableUIWithDocs(docsCheckUsername);
                        document.getElementById('loginphonenumber').value = '';
                        document.getElementById('starter').style.display = 'none';
                        document.getElementById('dashboard').style.display = 'block';
                        document.getElementById('datinglist').style.display = 'block';
                        document.getElementById('datingnotification').style.display = 'none';
                    }
                }else{
                    updateDatingListTableUIWithDocs(docsCheckPhone);
                    document.getElementById('loginphonenumber').value = '';
                    document.getElementById('starter').style.display = 'none';
                    document.getElementById('dashboard').style.display = 'block';
                    document.getElementById('datinglist').style.display = 'block';
                    document.getElementById('datingnotification').style.display = 'none';
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }
        }
    }
    // Adds the document to the HTML First table.
    // If the document already exists in the table, it will be updated.
    function datingDocToToTable(doc, datingTableTableBody){
        // Each row of the table has an id attribute set to the doc._id field.
        var tr = datingTableTableBody.querySelector(`#${doc._id}`);

        if (!tr) {
            tr = document.createElement("tr");
            tr.id = doc._id;
        }
      
        // Remove any existing table cells.
        while (tr.hasChildNodes()) {
            tr.removeChild(tr.childNodes[0]);
        }

        // Create new table cells for text fields.
        var tdDate = document.createElement("td");
        var localDate = new Date(doc.date);
        tdDate.innerText = localDate.toLocaleDateString();
        tr.appendChild(tdDate);

        // Create new table cells for text fields.
        var tdLookFor = document.createElement("td");
        var checkLook = doc.sexefor;
        if(checkLook === 'lookwoman'){
            tdLookFor.innerText = 'woman';
        }else{
            tdLookFor.innerText = 'man';
        }
        tr.appendChild(tdLookFor);

        // Create new table cells for text fields.
        var tdFrom = document.createElement("td");
        tdFrom.innerText = doc.prefer;
        tr.appendChild(tdFrom);

        // Create new table cells for text fields.
        var tdStatus = document.createElement("td");
        var btn = document.createElement('input');
        btn.type = "button";
        btn.className = "statusdating";
        btn.value = '☞';
        tdStatus.appendChild(btn);
        tdStatus.onclick = function (){
            document.getElementById('starter').style.display = 'none';
            document.getElementById('dashboard').style.display = 'block';
            document.getElementById('datinglist').style.display = 'none';
            document.getElementById('datingnotification').style.display = 'block';
        };
        tr.appendChild(tdStatus);

        // Add the row to the top of the table.
        datingTableTableBody.insertBefore(tr, datingTableTableBody.nextSibling);
    }
    /**Clear and update the UI to display the docs.*/
    function updateDatingListTableUIWithDocs(docs) {
        var datingTableTableBody = document.getElementById("datingTableTableBody");

        // Remove all child nodes from the table
        while (datingTableTableBody.hasChildNodes()) {
            datingTableTableBody.removeChild(datingTableTableBody.childNodes[0]);
        }

        // Add the new documents to the div
        for (var i = 0; i < docs.length; i++) {
            var doc = docs[i];
            datingDocToToTable(doc, datingTableTableBody);
        }
    }

    document.getElementById('getstartertwo').addEventListener('click', getstartertwoChange);
    async function getstartertwoChange(e){
        e.preventDefault();

        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        
        //alert
        var elementOutId = "modaloutalertstartertwo";
        var elementDropId = "backdropoutalertstartertwo";
        var buttonHandler = "ouiHandlerAlertStarterTwoBtn";

        if(sexeStr === '' || sexeforStr === ''){
            var pTextechooseone = "Choose I am and Looking for";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'block';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterthree').addEventListener('click', getstarterthreeChange);
    async function getstarterthreeChange(e){
        e.preventDefault();

        var ageStr = document.getElementById('age').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterthree";
        var elementDropId = "backdropoutalertstarterthree";
        var buttonHandler = "ouiHandlerAlertStarterThreeBtn";

        if(ageStr === ''){
            var pTextechooseone = "Choose your age range";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'block';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfour').addEventListener('click', getstarterfourChange);
    async function getstarterfourChange(e){
        e.preventDefault();

        var seekStr = document.getElementById('seek').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfour";
        var elementDropId = "backdropoutalertstarterfour";
        var buttonHandler = "ouiHandlerAlertStarterFourBtn";

        if(seekStr === ''){
            var pTextechooseone = "Choose what you are seeking";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            if(sexeforStr === 'lookwoman'){
                document.getElementById('starterfive').style.display = 'block';
                document.getElementById('starterfiveman').style.display = 'none';
            }else{
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'block';
            }
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfive').addEventListener('click', getstarterfiveChange);
    async function getstarterfiveChange(e){
        e.preventDefault();

        var preferStr = document.getElementById('prefer').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfive";
        var elementDropId = "backdropoutalertstarterfive";
        var buttonHandler = "ouiHandlerAlertStarterFiveBtn";

        if(preferStr === ''){
            var pTextechooseone = "Choose your group member";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'block';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterfiveman').addEventListener('click', getstarterfivemanChange);
    async function getstarterfivemanChange(e){
        e.preventDefault();

        var preferStr = document.getElementById('prefer').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterfiveman";
        var elementDropId = "backdropoutalertstarterfiveman";
        var buttonHandler = "ouiHandlerAlertStarterFiveManBtn";

        if(preferStr === ''){
            var pTextechooseone = "Choose your group member";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'block';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstartersix').addEventListener('click', getstartersixChange);
    async function getstartersixChange(e){
        e.preventDefault();

        var lifeStr = document.getElementById('life').innerText;
        
        //alert
        var elementOutId = "modaloutalertstartersix";
        var elementDropId = "backdropoutalertstartersix";
        var buttonHandler = "ouiHandlerAlertStarterSixBtn";

        if(lifeStr === ''){
            var pTextechooseone = "Choose your lifestyle";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'block';
            document.getElementById('startereight').style.display = 'none';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstarterseven').addEventListener('click', getstartersevenChange);
    async function getstartersevenChange(e){
        e.preventDefault();

        var contactStr = document.getElementById('contact').innerText;
        
        //alert
        var elementOutId = "modaloutalertstarterseven";
        var elementDropId = "backdropoutalertstarterseven";
        var buttonHandler = "ouiHandlerAlertStarterSevenBtn";

        //set up connection parameters
        var hostnameStr = '127.0.0.1';
        var portnumberStr = '5984';
        var usernameStr = 'kouaskon';
        var passwordStr = 'Mandidjouman1';
        var databaseStr = 'rishta';

        //set fields values
        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        var seekStr = document.getElementById('seek').innerText;
        var preferStr = document.getElementById('prefer').innerText;
        var sexematchStr = '';
        var sexeformatchStr = '';

        if(sexeStr === 'woman'){
            sexeformatchStr = 'lookwoman';
        }else if(sexeStr === 'man'){
            sexeformatchStr = 'lookman';
        }

        if(sexeforStr === 'lookman'){
            sexematchStr = 'man';
        }else{
            sexematchStr = 'woman';
        }

        if(contactStr === ''){
            var pTextechooseone = "Choose your prefered contact";
            alertModal(elementOutId, elementDropId, pTextechooseone, buttonHandler);
        }else{
            var docsMatch = [];
            var docsMatchFilter = [];
            try {
                const db = new PouchDB(databaseStr);
                const remote = new PouchDB("http://"+usernameStr +":"+passwordStr +"@"+hostnameStr +":"+portnumberStr +"/"+databaseStr);
                PouchDB.plugin(PouchFind);
                
                function syncbase() {
                    var opts = {
                      live: true,
                      retry: true,
                      continuous: true,
                      attachments: true,
                    };
                    db.sync(remote, opts);
                }
                if (remote) {
                    syncbase();
                }

                let result = await db.createIndex({
                    index: {
                        fields: [
                            "sexe", 
                            "sexfor", 
                            "origine",
                        ],
                    },
                });
                result = await db.find({
                    selector: {
                      sexe: sexematchStr,
                      sexefor: sexeformatchStr,
                      origine: preferStr,
                    },
                });
                docsMatch = result.docs;
                var resultatDocsMatch = docsMatch.length;
                if(resultatDocsMatch === 0){
                    document.getElementById('datingresult').innerText = resultatDocsMatch+'%';
                }else{
                    //add seek to filter the match
                    let resultfilter = await db.createIndex({
                        index: {
                            fields: [
                                "sexe", 
                                "sexfor",
                                "seek", 
                                "origine",
                            ],
                        },
                    });
                    resultfilter = await db.find({
                        selector: {
                          sexe: sexematchStr,
                          sexefor: sexeformatchStr,
                          seek: seekStr,
                          origine: preferStr,
                        },
                    });
                    docsMatchFilter = resultfilter.docs;
                    var resultatDocsMatchFilter = docsMatchFilter.length;
                    //calculate percentage
                    var resultmatch = ((resultatDocsMatchFilter / resultatDocsMatch) * 100);
                    var roundnumber = Math.ceil(resultmatch);
                    document.getElementById('datingresult').innerText = roundnumber+'%';
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
            }

            document.getElementById('landing').style.display = 'none';
            document.getElementById('starter').style.display = 'block';
            document.getElementById('starterone').style.display = 'none';
            document.getElementById('starterphone').style.display = 'none';
            document.getElementById('starterlogin').style.display = 'none';
            document.getElementById('startertwo').style.display = 'none';
            document.getElementById('starterthree').style.display = 'none';
            document.getElementById('starterfour').style.display = 'none';
            document.getElementById('starterfive').style.display = 'none';
            document.getElementById('starterfiveman').style.display = 'none';
            document.getElementById('startersix').style.display = 'none';
            document.getElementById('starterseven').style.display = 'none';
            document.getElementById('startereight').style.display = 'block';
            document.getElementById('starternine').style.display = 'none';
        }
    }

    document.getElementById('getstartereight').addEventListener('click', getstartereightChange);
    async function getstartereightChange(e){
        e.preventDefault();

        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'none';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'block';
    }

    //Login now
    document.getElementById('getstarternine').addEventListener('click', getstarternineChange);
    async function getstarternineChange(e){
        e.preventDefault();

        //alert
        var elementOutId = "modaloutalertstarternine";
        var elementDropId = "backdropoutalertstarternine";
        var buttonHandler = "ouiHandlerAlertNineBtn";

        //set up connection parameters
        var hostnameStr = '127.0.0.1';
        var portnumberStr = '5984';
        var usernameStr = 'kouaskon';
        var passwordStr = 'Mandidjouman1';
        var databaseStr = 'rishta';

        //set fields values
        var sexeStr = document.getElementById('sexe').innerText;
        var sexeforStr = document.getElementById('sexefor').innerText;
        var ageStr = document.getElementById('age').innerText;
        var seekStr = document.getElementById('seek').innerText;
        var preferStr = document.getElementById('prefer').innerText;
        var lifeStr = document.getElementById('life').innerText;
        var contactStr = document.getElementById('contact').innerText;
        var origineStr = document.getElementById('origine').value;
        var usernamelogStr = document.getElementById('username').value;
        var passwordlogStr = document.getElementById('password').value;
        var countryStr = document.getElementById('country').value;
        var locationStr = document.getElementById('location').value;
        var emailStr = document.getElementById('emailaddress').value;
        var telephoneStr = document.getElementById('telephone').value;
        var prefixeStr = '0044';
        var dateStr = getCurrentDate();
        var documentStr = 'starter';

        if(!sexeStr){
            var pTextesexe = "Choose I am";
            alertModal(elementOutId,elementDropId,pTextesexe,buttonHandler);
        }else if(!sexeforStr){
            var pTextesexefor = "Choose Looking for";
            alertModal(elementOutId,elementDropId,pTextesexefor,buttonHandler);
        }else if(!ageStr){
            var pTexteage = "Choose your age range";
            alertModal(elementOutId,elementDropId,pTexteage,buttonHandler);
        }else if(!seekStr){
            var pTexteseek = "Choose what you are seeking";
            alertModal(elementOutId,elementDropId,pTexteseek,buttonHandler);
        }else if(!preferStr){
            var pTexteprefer = "Choose your group member";
            alertModal(elementOutId,elementDropId,pTexteprefer,buttonHandler);
        }else if(!lifeStr){
            var pTextelife = "Choose your lifestyle";
            alertModal(elementOutId,elementDropId,pTextelife,buttonHandler);
        }else if(!contactStr){
            var pTextecontact = "Choose your prefred contact";
            alertModal(elementOutId,elementDropId,pTextecontact,buttonHandler);
        }else if(!origineStr){
            var pTexteorigine = "Enter the country you're from";
            alertModal(elementOutId,elementDropId,pTexteorigine,buttonHandler);
        }else if(!usernamelogStr){
            var pTexteusername = "Enter your username";
            alertModal(elementOutId,elementDropId,pTexteusername,buttonHandler);
        }else if(!passwordlogStr){
            var pTextepassword = "Enter your password";
            alertModal(elementOutId,elementDropId,pTextepassword,buttonHandler);
        }else if(!countryStr){
            var pTextecountry = "Enter the country you currently live";
            alertModal(elementOutId,elementDropId,pTextecountry,buttonHandler);
        }else if(!locationStr){
            var pTextelocation = "Enter the city you currently live";
            alertModal(elementOutId,elementDropId,pTextelocation,buttonHandler);
        }else if(!emailStr){
            var pTextemail = "Enter your valid email";
            alertModal(elementOutId,elementDropId,pTextemail,buttonHandler);
        }else if(!telephoneStr){
            var pTextetelephone = "Confirm your valid phone number";
            alertModal(elementOutId,elementDropId,pTextetelephone,buttonHandler);
        }else{
            const dbbasedoc = new PouchDB(databaseStr);
            const remotebasedoc = new PouchDB("http://"+usernameStr +":"+passwordStr +"@"+hostnameStr +":"+portnumberStr +"/"+databaseStr);
            PouchDB.plugin(PouchFind);

            function syncbase() {
                var opts = {
                  live: true,
                  retry: true,
                  continuous: true,
                  attachments: true,
                };
                dbbasedoc.sync(remotebasedoc, opts);
            }
            if (remotebasedoc) {
                syncbase();
            }

            try {
                var docsCheckConfirm = [];
                //check if new phone number in StarterNine.js input matches previous in StarterPhone.js
                let result = await dbbasedoc.createIndex({
                    index: {
                      fields: ["prefixe", "telephone"],
                    }
                });
                result = await dbbasedoc.find({
                    selector: {
                      prefixe: prefixeStr,
                      telephone: telephoneStr,
                    }
                });
                docsCheckConfirm = result.docs;
                var resultatCheckConfirm = docsCheckConfirm.length;
                if (resultatCheckConfirm === 0){
                    var pTextemissmatch = "Phone number does not match";
                    alertModal(elementOutId,elementDropId,pTextemissmatch,buttonHandler);
                }else{
                    //Create the document _id and _rev.
                    var now = Date.now();
                    //new data table id
                    var id = documentStr.toLowerCase()+"_" + now;
                    // Add the document to the database.
                    var response = await dbbasedoc.put({
                        _id: id,
                        sexe: sexeStr,
                        sexefor: sexeforStr,
                        age: ageStr,
                        seek: seekStr,
                        prefer: preferStr,
                        life: lifeStr,
                        origine: origineStr,
                        contact: contactStr,
                        username: usernamelogStr,
                        password: passwordlogStr,
                        country: countryStr,
                        location: locationStr,
                        email: emailStr,
                        phone: telephoneStr,
                        date: dateStr,
                    });
                    // check if document inserted
                    var doc = await dbbasedoc.get(response.id);
                    if (doc !== ""){
                        var divClearModal = document.getElementById("modaloutstarternine");
                        let divModal = document.createElement("div");
                        divModal.innerHTML = `
                        <div class="modal">
                            <p>Username: ${usernamelogStr} / Password: ${passwordlogStr} logged Succesfully</p>
                            <button class="btn btn--alt" id="ouiHandlerStarterNineBtn">OK</button>
                        </div>`;
                        divClearModal.appendChild(divModal);

                        var divClearBackdrop = document.getElementById("backdropoutstarternine");
                        let divBack = document.createElement("div");
                        divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseStarterNineBtn"></div>`;
                        divClearBackdrop.appendChild(divBack);

                        //If OK Clicked
                        document.getElementById("ouiHandlerStarterNineBtn").addEventListener("click", ouiHandlerStarterNineBtnChange);
                        async function ouiHandlerStarterNineBtnChange(e){
                            e.preventDefault();

                            //xxxxxxxxxxxxxxxxxxxxx
                            var labelSexe = document.getElementById("sexe");
                            var labelSexefor = document.getElementById("sexefor");
                            var labelAge = document.getElementById("age");
                            var labelSeek = document.getElementById("seek");
                            var labelPrefer = document.getElementById("prefer");
                            var labelLife = document.getElementById("life");
                            var labelContact = document.getElementById("contact");
                            // Clear labels.
                            while(labelSexe.hasChildNodes()) {
                                labelSexe.removeChild(labelSexe.childNodes[0]);
                            };
                            while(labelSexefor.hasChildNodes()) {
                                labelSexefor.removeChild(labelSexefor.childNodes[0]);
                            };
                            while(labelAge.hasChildNodes()) {
                                labelAge.removeChild(labelAge.childNodes[0]);
                            };
                            while(labelSeek.hasChildNodes()) {
                                labelSeek.removeChild(labelSeek.childNodes[0]);
                            };
                            while(labelPrefer.hasChildNodes()) {
                                labelPrefer.removeChild(labelPrefer.childNodes[0]);
                            };
                            while(labelLife.hasChildNodes()) {
                                labelLife.removeChild(labelLife.childNodes[0]);
                            };
                            while(labelContact.hasChildNodes()) {
                                labelContact.removeChild(labelContact.childNodes[0]);
                            };

                            //desable all checkboxes
                            document.getElementById('iamman').checked = false ;
                            document.getElementById('iamwoman').checked = false ;
                            document.getElementById('lookingmman').checked = false ;
                            document.getElementById('lookingwoman').checked = false
                            //
                            document.getElementById('myage24').checked = false ;
                            document.getElementById('myage25').checked = false ;
                            document.getElementById('myage35').checked = false ;
                            document.getElementById('myage45').checked = false ;
                            document.getElementById('myage55').checked = false ;
                            document.getElementById('myage65').checked = false ;
                            //
                            document.getElementById('seekserious').checked = false ;
                            document.getElementById('seeklife').checked = false ;
                            document.getElementById('seekcompanion').checked = false ;
                            document.getElementById('seektravel').checked = false ;
                            document.getElementById('seekonline').checked = false ;
                            document.getElementById('seekflirting').checked = false ;
                            //
                            document.getElementById('preferindia').checked = false ;
                            document.getElementById('preferpakistan').checked = false ;
                            document.getElementById('preferbengali').checked = false ;
                            document.getElementById('preferafghan').checked = false ;
                            document.getElementById('preferindonesia').checked = false ;
                            document.getElementById('preferiran').checked = false ;
                            document.getElementById('preferturkey').checked = false ;
                            document.getElementById('prefermalaysia').checked = false ;
                            //
                            document.getElementById('manpreferindia').checked = false ;
                            document.getElementById('manpreferpakistan').checked = false ;
                            document.getElementById('manpreferbengali').checked = false ;
                            document.getElementById('manpreferafghan').checked = false ;
                            document.getElementById('manpreferindonesia').checked = false ;
                            document.getElementById('manpreferiran').checked = false ;
                            document.getElementById('manpreferturkey').checked = false ;
                            document.getElementById('manprefermalaysia').checked = false ;
                            //
                            document.getElementById('lifetravel').checked = false ;
                            document.getElementById('lifenature').checked = false ;
                            document.getElementById('lifesport').checked = false ;
                            document.getElementById('lifebeach').checked = false ;
                            //
                            document.getElementById('texte').checked = false ;
                            document.getElementById('meeting').checked = false ;
                            document.getElementById('email').checked = false ;
                            document.getElementById('calling').checked = false ;
                            //xxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('landing').style.display = 'none';
                            document.getElementById('starter').style.display = 'block';
                            document.getElementById('starterone').style.display = 'block';
                            document.getElementById('starterphone').style.display = 'none';
                            document.getElementById('starterlogin').style.display = 'none';
                            document.getElementById('startertwo').style.display = 'none';
                            document.getElementById('starterthree').style.display = 'none';
                            document.getElementById('starterfour').style.display = 'none';
                            document.getElementById('starterfive').style.display = 'none';
                            document.getElementById('starterfiveman').style.display = 'none';
                            document.getElementById('startersix').style.display = 'none';
                            document.getElementById('starterseven').style.display = 'none';
                            document.getElementById('startereight').style.display = 'none';
                            document.getElementById('starternine').style.display = 'none';

                            var divClearModal = document.getElementById("modaloutstarternine");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarternine");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }

                        //If backdrop Clicked
                        document.getElementById("backdropHandlerCloseStarterNineBtn").addEventListener("click", backdropHandlerCloseStarterNineBtnChange);
                        async function backdropHandlerCloseStarterNineBtnChange(e) {
                            e.preventDefault();

                            var divClearModal = document.getElementById("modaloutstarternine");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarternine");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }
                    }else{
                        var divClearModal = document.getElementById("modaloutstarternine");
                        let divModal = document.createElement("div");
                        divModal.innerHTML = `
                        <div class="modal">
                            <p>Username: ${usernamelogStr} / Password: ${passwordlogStr} logged Failed</p>
                            <button class="btn btn--alt" id="ouiHandlerFailedStarterNineBtn">OK</button>
                        </div>`;
                        divClearModal.appendChild(divModal);

                        var divClearBackdrop = document.getElementById("backdropoutstarternine");
                        let divBack = document.createElement("div");
                        divBack.innerHTML = `<div class="backdrop" id="backdropHandlerCloseFailedStarterNineBtn"></div>`;
                        divClearBackdrop.appendChild(divBack);

                        //If OK Clicked
                        document.getElementById("ouiHandlerFailedStarterNineBtn").addEventListener("click", ouiHandlerFailedStarterNineBtnChange);
                        async function ouiHandlerFailedStarterNineBtnChange(e){
                            e.preventDefault();

                            //xxxxxxxxxxxxxxxxxxxxx
                            var labelSexe = document.getElementById("sexe");
                            var labelSexefor = document.getElementById("sexefor");
                            var labelAge = document.getElementById("age");
                            var labelSeek = document.getElementById("seek");
                            var labelPrefer = document.getElementById("prefer");
                            var labelLife = document.getElementById("life");
                            var labelContact = document.getElementById("contact");
                            // Clear labels.
                            while(labelSexe.hasChildNodes()) {
                                labelSexe.removeChild(labelSexe.childNodes[0]);
                            };
                            while(labelSexefor.hasChildNodes()) {
                                labelSexefor.removeChild(labelSexefor.childNodes[0]);
                            };
                            while(labelAge.hasChildNodes()) {
                                labelAge.removeChild(labelAge.childNodes[0]);
                            };
                            while(labelSeek.hasChildNodes()) {
                                labelSeek.removeChild(labelSeek.childNodes[0]);
                            };
                            while(labelPrefer.hasChildNodes()) {
                                labelPrefer.removeChild(labelPrefer.childNodes[0]);
                            };
                            while(labelLife.hasChildNodes()) {
                                labelLife.removeChild(labelLife.childNodes[0]);
                            };
                            while(labelContact.hasChildNodes()) {
                                labelContact.removeChild(labelContact.childNodes[0]);
                            };

                            //desable all checkboxes
                            document.getElementById('iamman').checked = false ;
                            document.getElementById('iamwoman').checked = false ;
                            document.getElementById('lookingmman').checked = false ;
                            document.getElementById('lookingwoman').checked = false
                            //
                            document.getElementById('myage24').checked = false ;
                            document.getElementById('myage25').checked = false ;
                            document.getElementById('myage35').checked = false ;
                            document.getElementById('myage45').checked = false ;
                            document.getElementById('myage55').checked = false ;
                            document.getElementById('myage65').checked = false ;
                            //
                            document.getElementById('seekserious').checked = false ;
                            document.getElementById('seeklife').checked = false ;
                            document.getElementById('seekcompanion').checked = false ;
                            document.getElementById('seektravel').checked = false ;
                            document.getElementById('seekonline').checked = false ;
                            document.getElementById('seekflirting').checked = false ;
                            //
                            document.getElementById('preferindia').checked = false ;
                            document.getElementById('preferpakistan').checked = false ;
                            document.getElementById('preferbengali').checked = false ;
                            document.getElementById('preferafghan').checked = false ;
                            document.getElementById('preferindonesia').checked = false ;
                            document.getElementById('preferiran').checked = false ;
                            document.getElementById('preferturkey').checked = false ;
                            document.getElementById('prefermalaysia').checked = false ;
                            //
                            document.getElementById('manpreferindia').checked = false ;
                            document.getElementById('manpreferpakistan').checked = false ;
                            document.getElementById('manpreferbengali').checked = false ;
                            document.getElementById('manpreferafghan').checked = false ;
                            document.getElementById('manpreferindonesia').checked = false ;
                            document.getElementById('manpreferiran').checked = false ;
                            document.getElementById('manpreferturkey').checked = false ;
                            document.getElementById('manprefermalaysia').checked = false ;
                            //
                            document.getElementById('lifetravel').checked = false ;
                            document.getElementById('lifenature').checked = false ;
                            document.getElementById('lifesport').checked = false ;
                            document.getElementById('lifebeach').checked = false ;
                            //
                            document.getElementById('texte').checked = false ;
                            document.getElementById('meeting').checked = false ;
                            document.getElementById('email').checked = false ;
                            document.getElementById('calling').checked = false ;
                            //xxxxxxxxxxxxxxxxxxxxx
                            document.getElementById('landing').style.display = 'none';
                            document.getElementById('starter').style.display = 'block';
                            document.getElementById('starterone').style.display = 'block';
                            document.getElementById('starterphone').style.display = 'none';
                            document.getElementById('starterlogin').style.display = 'none';
                            document.getElementById('startertwo').style.display = 'none';
                            document.getElementById('starterthree').style.display = 'none';
                            document.getElementById('starterfour').style.display = 'none';
                            document.getElementById('starterfive').style.display = 'none';
                            document.getElementById('starterfiveman').style.display = 'none';
                            document.getElementById('startersix').style.display = 'none';
                            document.getElementById('starterseven').style.display = 'none';
                            document.getElementById('startereight').style.display = 'none';
                            document.getElementById('starternine').style.display = 'none';

                            var divClearModal = document.getElementById("modaloutstarternine");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarternine");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }

                        //If backdrop Clicked
                        document.getElementById("backdropHandlerCloseFailedStarterNineBtn").addEventListener("click", backdropHandlerCloseFailedStarterNineBtnChange);
                        async function backdropHandlerCloseFailedStarterNineBtnChange(e) {
                            e.preventDefault();

                            var divClearModal = document.getElementById("modaloutstarternine");
                            while (divClearModal.hasChildNodes()) {
                                divClearModal.removeChild(divClearModal.childNodes[0]);
                            }

                            var divClearBackdrop = document.getElementById("backdropoutstarternine");
                            while (divClearBackdrop.hasChildNodes()) {
                                divClearBackdrop.removeChild(divClearBackdrop.childNodes[0]);
                            }
                        }
                    }
                }
            } catch (error) {
                var pTexteerreurconnection = "An Error has Occurred. Clic back to Start";
                alertModal(elementOutId,elementDropId,pTexteerreurconnection,buttonHandler);
                //xxxxxxxxxxxxxxxxxxxxx
                var labelSexe = document.getElementById("sexe");
                var labelSexefor = document.getElementById("sexefor");
                var labelAge = document.getElementById("age");
                var labelSeek = document.getElementById("seek");
                var labelPrefer = document.getElementById("prefer");
                var labelLife = document.getElementById("life");
                var labelContact = document.getElementById("contact");
                // Clear labels.
                while(labelSexe.hasChildNodes()) {
                    labelSexe.removeChild(labelSexe.childNodes[0]);
                };
                while(labelSexefor.hasChildNodes()) {
                    labelSexefor.removeChild(labelSexefor.childNodes[0]);
                };
                while(labelAge.hasChildNodes()) {
                    labelAge.removeChild(labelAge.childNodes[0]);
                };
                while(labelSeek.hasChildNodes()) {
                    labelSeek.removeChild(labelSeek.childNodes[0]);
                };
                while(labelPrefer.hasChildNodes()) {
                    labelPrefer.removeChild(labelPrefer.childNodes[0]);
                };
                while(labelLife.hasChildNodes()) {
                    labelLife.removeChild(labelLife.childNodes[0]);
                };
                while(labelContact.hasChildNodes()) {
                    labelContact.removeChild(labelContact.childNodes[0]);
                };
                //xxxxxxxxxxxxxxxxxxxxx
                document.getElementById('landing').style.display = 'none';
                document.getElementById('starter').style.display = 'block';
                document.getElementById('starterone').style.display = 'none';
                document.getElementById('starterphone').style.display = 'none';
                document.getElementById('starterlogin').style.display = 'none';
                document.getElementById('startertwo').style.display = 'none';
                document.getElementById('starterthree').style.display = 'none';
                document.getElementById('starterfour').style.display = 'none';
                document.getElementById('starterfive').style.display = 'none';
                document.getElementById('starterfiveman').style.display = 'none';
                document.getElementById('startersix').style.display = 'none';
                document.getElementById('starterseven').style.display = 'none';
                document.getElementById('startereight').style.display = 'none';
                document.getElementById('starternine').style.display = 'block';
            }
        }
    }

    //coding all checkboxes
    //startertwo
    //1.i am a woman
    document.getElementById('iamwoman').addEventListener('change', iamwomanChange);
    async function iamwomanChange(e){
        e.preventDefault();

        var iamwomanStr = document.getElementById('iamwoman').value;
        var sexeStr = document.getElementById('sexe');
        sexeStr.innerText = iamwomanStr;
        document.getElementById('iamman').checked = false ;
    }

    //2.i am a man
    document.getElementById('iamman').addEventListener('change', iammanChange);
    async function iammanChange(e){
        e.preventDefault();

        var iammanStr = document.getElementById('iamman').value;
        var sexeStr = document.getElementById('sexe');
        sexeStr.innerText = iammanStr;
        document.getElementById('iamwoman').checked = false ;
    }

    //3.looking for a woman
    document.getElementById('lookingwoman').addEventListener('change', lookingwomanChange);
    async function lookingwomanChange(e){
        e.preventDefault();

        var lookingwomanStr = document.getElementById('lookingwoman').value;
        var sexeforStr = document.getElementById('sexefor');
        sexeforStr.innerText = lookingwomanStr;
        document.getElementById('lookingmman').checked = false ; //activate if only one choice acceptable
    }

    //4.looking for a man
    document.getElementById('lookingmman').addEventListener('change', lookingmmanChange);
    async function lookingmmanChange(e){
        e.preventDefault();

        var lookingmmanStr = document.getElementById('lookingmman').value;
        var sexeforStr = document.getElementById('sexefor');
        sexeforStr.innerText = lookingmmanStr;
        document.getElementById('lookingwoman').checked = false ; //activate if only one choice acceptable
    }

    //starterthree
    //1.myage24
    document.getElementById('myage24').addEventListener('change', myage24Change);
    async function myage24Change(e){
        e.preventDefault();

        var myage24Str = document.getElementById('myage24').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage24Str;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
    }

    //1.myage25
    document.getElementById('myage25').addEventListener('change', myage25Change);
    async function myage25Change(e){
        e.preventDefault();

        var myage25Str = document.getElementById('myage25').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage25Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
    }

    //1.myage35
    document.getElementById('myage35').addEventListener('change', myage35Change);
    async function myage35Change(e){
        e.preventDefault();

        var myage35Str = document.getElementById('myage35').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage35Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
    }

    //1.myage45
    document.getElementById('myage45').addEventListener('change', myage45Change);
    async function myage45Change(e){
        e.preventDefault();

        var myage45Str = document.getElementById('myage45').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage45Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
    }

    //1.myage55
    document.getElementById('myage55').addEventListener('change', myage55Change);
    async function myage55Change(e){
        e.preventDefault();

        var myage55Str = document.getElementById('myage55').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage55Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage65').checked = false ;
    }

    //1.myage65
    document.getElementById('myage65').addEventListener('change', myage65Change);
    async function myage65Change(e){
        e.preventDefault();

        var myage65Str = document.getElementById('myage65').value;
        var ageStr = document.getElementById('age');
        ageStr.innerText = myage65Str;
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
    }

    //starterfour
    //1.seekserious
    document.getElementById('seekserious').addEventListener('change', seekseriousChange);
    async function seekseriousChange(e){
        e.preventDefault();

        var seekseriousStr = document.getElementById('seekserious').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekseriousStr;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
    }

    //2.seeklife
    document.getElementById('seeklife').addEventListener('change', seeklifeChange);
    async function seeklifeChange(e){
        e.preventDefault();

        var seeklifeStr = document.getElementById('seeklife').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seeklifeStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
    }

    //3.seekcompanion
    document.getElementById('seekcompanion').addEventListener('change', seekcompanionChange);
    async function seekcompanionChange(e){
        e.preventDefault();

        var seekcompanionStr = document.getElementById('seekcompanion').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekcompanionStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
    }

    //4.seektravel
    document.getElementById('seektravel').addEventListener('change', seektravelChange);
    async function seektravelChange(e){
        e.preventDefault();

        var seektravelStr = document.getElementById('seektravel').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seektravelStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
    }

    //5.seekonline
    document.getElementById('seekonline').addEventListener('change', seekonlineChange);
    async function seekonlineChange(e){
        e.preventDefault();

        var seekonlineStr = document.getElementById('seekonline').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekonlineStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekflirting').checked = false ;
    }

    //6.seekonline
    document.getElementById('seekflirting').addEventListener('change', seekflirtingChange);
    async function seekflirtingChange(e){
        e.preventDefault();

        var seekflirtingStr = document.getElementById('seekflirting').value;
        var seekStr = document.getElementById('seek');
        seekStr.innerText = seekflirtingStr;
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seekonline').checked = false ;
    }

    //starterfive
    //1.preferindia
    document.getElementById('preferindia').addEventListener('change', preferindiaChange);
    async function preferindiaChange(e){
        e.preventDefault();

        var preferindiaStr = document.getElementById('preferindia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferindiaStr;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferpakistan
    document.getElementById('preferpakistan').addEventListener('change', preferpakistanChange);
    async function preferpakistanChange(e){
        e.preventDefault();

        var preferpakistanStr = document.getElementById('preferpakistan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferpakistanStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferbengali
    document.getElementById('preferbengali').addEventListener('change', preferbengaliChange);
    async function preferbengaliChange(e){
        e.preventDefault();

        var preferbengaliStr = document.getElementById('preferbengali').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferbengaliStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferafghan
    document.getElementById('preferafghan').addEventListener('change', preferafghanChange);
    async function preferafghanChange(e){
        e.preventDefault();

        var preferafghanStr = document.getElementById('preferafghan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferafghanStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferindonesia
    document.getElementById('preferindonesia').addEventListener('change', preferindonesiaChange);
    async function preferindonesiaChange(e){
        e.preventDefault();

        var preferindonesiaStr = document.getElementById('preferindonesia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferindonesiaStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferiran
    document.getElementById('preferiran').addEventListener('change', preferiranChange);
    async function preferiranChange(e){
        e.preventDefault();

        var preferiranStr = document.getElementById('preferiran').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferiranStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.preferturkey
    document.getElementById('preferturkey').addEventListener('change', preferturkeyChange);
    async function preferturkeyChange(e){
        e.preventDefault();

        var preferturkeyStr = document.getElementById('preferturkey').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = preferturkeyStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
    }

    //1.prefermalaysia
    document.getElementById('prefermalaysia').addEventListener('change', prefermalaysiaChange);
    async function prefermalaysiaChange(e){
        e.preventDefault();

        var prefermalaysiaStr = document.getElementById('prefermalaysia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = prefermalaysiaStr;
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
    }

    //starterfiveman
    //1.manpreferindia
    document.getElementById('manpreferindia').addEventListener('change', manpreferindiaChange);
    async function manpreferindiaChange(e){
        e.preventDefault();

        var manpreferindiaStr = document.getElementById('manpreferindia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferindiaStr;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferpakistan
    document.getElementById('manpreferpakistan').addEventListener('change', manpreferpakistanChange);
    async function manpreferpakistanChange(e){
        e.preventDefault();

        var manpreferpakistanStr = document.getElementById('manpreferpakistan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferpakistanStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferbengali
    document.getElementById('manpreferbengali').addEventListener('change', manpreferbengaliChange);
    async function manpreferbengaliChange(e){
        e.preventDefault();

        var manpreferbengaliStr = document.getElementById('manpreferbengali').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferbengaliStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferafghan
    document.getElementById('manpreferafghan').addEventListener('change', manpreferafghanChange);
    async function manpreferafghanChange(e){
        e.preventDefault();

        var manpreferafghanStr = document.getElementById('manpreferafghan').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferafghanStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferindonesia
    document.getElementById('manpreferindonesia').addEventListener('change', manpreferindonesiaChange);
    async function manpreferindonesiaChange(e){
        e.preventDefault();

        var manpreferindonesiaStr = document.getElementById('manpreferindonesia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferindonesiaStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferiran
    document.getElementById('manpreferiran').addEventListener('change', manpreferiranChange);
    async function manpreferiranChange(e){
        e.preventDefault();

        var manpreferiranStr = document.getElementById('manpreferiran').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferiranStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manpreferturkey
    document.getElementById('manpreferturkey').addEventListener('change', manpreferturkeyChange);
    async function manpreferturkeyChange(e){
        e.preventDefault();

        var manpreferturkeyStr = document.getElementById('manpreferturkey').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manpreferturkeyStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
    }

    //1.manprefermalaysia
    document.getElementById('manprefermalaysia').addEventListener('change', manprefermalaysiaChange);
    async function manprefermalaysiaChange(e){
        e.preventDefault();

        var manprefermalaysiaStr = document.getElementById('manprefermalaysia').value;
        var preferStr = document.getElementById('prefer');
        preferStr.innerText = manprefermalaysiaStr;
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
    }

    //startersix
    //1.lifetravel
    document.getElementById('lifetravel').addEventListener('change', lifetravelChange);
    async function lifetravelChange(e){
        e.preventDefault();

        var lifetravelStr = document.getElementById('lifetravel').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifetravelStr;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
    }

    document.getElementById('lifenature').addEventListener('change', lifenatureChange);
    async function lifenatureChange(e){
        e.preventDefault();

        var lifenatureStr = document.getElementById('lifenature').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifenatureStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
    }

    document.getElementById('lifesport').addEventListener('change', lifesportChange);
    async function lifesportChange(e){
        e.preventDefault();

        var lifesportStr = document.getElementById('lifesport').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifesportStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifebeach').checked = false ;
    }

    document.getElementById('lifebeach').addEventListener('change', lifebeachChange);
    async function lifebeachChange(e){
        e.preventDefault();

        var lifebeachStr = document.getElementById('lifebeach').value;
        var lifeStr = document.getElementById('life');
        lifeStr.innerText = lifebeachStr;
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
    }

    //starterseven
    //1.texte
    document.getElementById('texte').addEventListener('change', texteChange);
    async function texteChange(e){
        e.preventDefault();

        var texteStr = document.getElementById('texte').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = texteStr;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;
    }

    //1.meeting
    document.getElementById('meeting').addEventListener('change', meetingChange);
    async function meetingChange(e){
        e.preventDefault();

        var meetingStr = document.getElementById('meeting').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = meetingStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;
    }

    //1.email
    document.getElementById('email').addEventListener('change', emailChange);
    async function emailChange(e){
        e.preventDefault();

        var emailStr = document.getElementById('email').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = emailStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('calling').checked = false ;
    }

    //1.calling
    document.getElementById('calling').addEventListener('change', callingChange);
    async function callingChange(e){
        e.preventDefault();

        var callingStr = document.getElementById('calling').value;
        var contactStr = document.getElementById('contact');
        contactStr.innerText = callingStr;
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
    }

    //Set country you currently live
    document.getElementById('country').addEventListener('click', countryChange);
    async function countryChange(e){
        e.preventDefault();

        const column = ["United Kingdom","India","Pakistan","Bengladesh","Afghanistan","Indonesia","Turkey","Koweit","Qatar"];
        autocomplete(document.getElementById("country"), column);
    }

    //Set Location/city you currently live
    document.getElementById('location').addEventListener('click', locationChange);
    async function locationChange(e){
        e.preventDefault();

        const column = ["United Kingdom","India","Pakistan","Bengladesh","Afghanistan","Indonesia","Turkey","Koweit","Qatar"];
        autocomplete(document.getElementById("location"), column);
    }

    //backstartphone
    document.getElementById('backstartphone').addEventListener('click', backstartphoneChange);
    async function backstartphoneChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //backstartlogin
    document.getElementById('backstartlogin').addEventListener('click', backstartloginChange);
    async function backstartloginChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarttwo
    document.getElementById('backstarttwo').addEventListener('click', backstarttwoChange);
    async function backstarttwoChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartthree
    document.getElementById('backstartthree').addEventListener('click', backstartthreeChange);
    async function backstartthreeChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfour
    document.getElementById('backstartfour').addEventListener('click', backstartfourChange);
    async function backstartfourChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfive
    document.getElementById('backstartfive').addEventListener('click', backstartfiveChange);
    async function backstartfiveChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartfiveman
    document.getElementById('backstartfiveman').addEventListener('click', backstartfivemanChange);
    async function backstartfivemanChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartsix
    document.getElementById('backstartsix').addEventListener('click', backstartsixChange);
    async function backstartsixChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstartseven
    document.getElementById('backstartseven').addEventListener('click', backstartsevenChange);
    async function backstartsevenChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarteight
    document.getElementById('backstarteight').addEventListener('click', backstarteightChange);
    async function backstarteightChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }

    //Back to Start coding
    //backstarternine
    document.getElementById('backstartnine').addEventListener('click', backstartnineChange);
    async function backstartnineChange(e){
        e.preventDefault();

        var labelSexe = document.getElementById("sexe");
        var labelSexefor = document.getElementById("sexefor");
        var labelAge = document.getElementById("age");
        var labelSeek = document.getElementById("seek");
        var labelPrefer = document.getElementById("prefer");
        var labelLife = document.getElementById("life");
        var labelContact = document.getElementById("contact");
        // Clear labels.
        while(labelSexe.hasChildNodes()) {
            labelSexe.removeChild(labelSexe.childNodes[0]);
        };
        while(labelSexefor.hasChildNodes()) {
            labelSexefor.removeChild(labelSexefor.childNodes[0]);
        };
        while(labelAge.hasChildNodes()) {
            labelAge.removeChild(labelAge.childNodes[0]);
        };
        while(labelSeek.hasChildNodes()) {
            labelSeek.removeChild(labelSeek.childNodes[0]);
        };
        while(labelPrefer.hasChildNodes()) {
            labelPrefer.removeChild(labelPrefer.childNodes[0]);
        };
        while(labelLife.hasChildNodes()) {
            labelLife.removeChild(labelLife.childNodes[0]);
        };
        while(labelContact.hasChildNodes()) {
            labelContact.removeChild(labelContact.childNodes[0]);
        };

        //desable all checkboxes
        document.getElementById('iamman').checked = false ;
        document.getElementById('iamwoman').checked = false ;
        document.getElementById('lookingmman').checked = false ;
        document.getElementById('lookingwoman').checked = false
        //
        document.getElementById('myage24').checked = false ;
        document.getElementById('myage25').checked = false ;
        document.getElementById('myage35').checked = false ;
        document.getElementById('myage45').checked = false ;
        document.getElementById('myage55').checked = false ;
        document.getElementById('myage65').checked = false ;
        //
        document.getElementById('seekserious').checked = false ;
        document.getElementById('seeklife').checked = false ;
        document.getElementById('seekcompanion').checked = false ;
        document.getElementById('seektravel').checked = false ;
        document.getElementById('seekonline').checked = false ;
        document.getElementById('seekflirting').checked = false ;
        //
        document.getElementById('preferindia').checked = false ;
        document.getElementById('preferpakistan').checked = false ;
        document.getElementById('preferbengali').checked = false ;
        document.getElementById('preferafghan').checked = false ;
        document.getElementById('preferindonesia').checked = false ;
        document.getElementById('preferiran').checked = false ;
        document.getElementById('preferturkey').checked = false ;
        document.getElementById('prefermalaysia').checked = false ;
        //
        document.getElementById('manpreferindia').checked = false ;
        document.getElementById('manpreferpakistan').checked = false ;
        document.getElementById('manpreferbengali').checked = false ;
        document.getElementById('manpreferafghan').checked = false ;
        document.getElementById('manpreferindonesia').checked = false ;
        document.getElementById('manpreferiran').checked = false ;
        document.getElementById('manpreferturkey').checked = false ;
        document.getElementById('manprefermalaysia').checked = false ;
        //
        document.getElementById('lifetravel').checked = false ;
        document.getElementById('lifenature').checked = false ;
        document.getElementById('lifesport').checked = false ;
        document.getElementById('lifebeach').checked = false ;
        //
        document.getElementById('texte').checked = false ;
        document.getElementById('meeting').checked = false ;
        document.getElementById('email').checked = false ;
        document.getElementById('calling').checked = false ;

        //xxxxxxxxxx
        document.getElementById('landing').style.display = 'none';
        document.getElementById('starter').style.display = 'block';
        document.getElementById('starterone').style.display = 'block';
        document.getElementById('starterphone').style.display = 'none';
        document.getElementById('starterlogin').style.display = 'none';
        document.getElementById('startertwo').style.display = 'none';
        document.getElementById('starterthree').style.display = 'none';
        document.getElementById('starterfour').style.display = 'none';
        document.getElementById('starterfive').style.display = 'none';
        document.getElementById('starterfiveman').style.display = 'none';
        document.getElementById('startersix').style.display = 'none';
        document.getElementById('starterseven').style.display = 'none';
        document.getElementById('startereight').style.display = 'none';
        document.getElementById('starternine').style.display = 'none';
    }
});

/**1: alertmodal function*/
function alertModal(elementOutId, elementDropId, pTexte, buttonHandler) {
    var divClearModal = document.getElementById(elementOutId);
    let divModal = document.createElement("div");
    divModal.innerHTML = `<div class="modalalert">
          <p>${pTexte}</p>
          <button class="btnalert btnalert--alt" id="${buttonHandler}">OK</button>
      </div>`;
    divClearModal.appendChild(divModal);
  
    var divClearBackdrop = document.getElementById(elementDropId);
    let divBack = document.createElement("div");
    divBack.innerHTML = `<div class="backdropalert"></div>`;
    divClearBackdrop.appendChild(divBack);
  
    var buttonChange = document.getElementById(buttonHandler);
    buttonChange.addEventListener("click", (e) => {
      e.preventDefault();
  
      var divClearModal = document.getElementById(elementOutId);
      while (divClearModal.hasChildNodes()) {
        divClearModal.removeChild(divClearModal.childNodes[0]);
      }
  
      var divClearModalDrop = document.getElementById(elementDropId);
      while (divClearModalDrop.hasChildNodes()) {
        divClearModalDrop.removeChild(divClearModalDrop.childNodes[0]);
      }
    });
}

/**implement autocomplete list of columns function */
function autocomplete(inp, arr){
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function(e) {
        var a, b, i, val = this.value;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if(!val){return false;}
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV");
        a.setAttribute("id", this.id + "autocomplete-list");
        a.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a);
        /*for each item in the array...*/
        for(i=0; i<arr.length; i++){
            /*check if the item starts with the same letters as the text field value:*/
            if(arr[i].substr(0, val.length).toUpperCase() === val.toUpperCase()){
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV");
                /*make the matching letters bold:*/
                b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                b.innerHTML += arr[i].substr(val.length);
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function(e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;
                    /*close the list of autocompleted values,(or any other open lists of autocompleted values:*/
                    closeAllLists();
                });
                a.appendChild(b);
            }
        }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode === 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode === 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
              /*and simulate a click on the "active" item:*/
              if (x) x[currentFocus].click();
            }
        }
    });
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
          if (elmnt !== x[i] && elmnt !== inp) {
            x[i].parentNode.removeChild(x[i]);
          }
        }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
};

function getCurrentDate() {
    // Date object
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    // Current Date
    var date = yyyy + "-" + mm + "-" + dd;
    return date;
}