import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    FormContent, 
    BackStart,  
} from '../StarterStyles';

function StarterTwo() {
    return ( 
        <StarterContainer style={FontEcran} id="startertwo">
            <FormScroller>
                <br/>
                <TextNormal>I am</TextNormal>
                <FormContent>
                    <div>
                        <input type="checkbox" id="iamwoman" name="iamwoman" value="woman" />
                        <label htmlFor="iamwoamn"> a woman</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="iamman" name="iamman" value="man" />
                        <label htmlFor="iamman"> a man</label><br/>
                    </div>
                </FormContent>
                
                <TextNormal>Looking for</TextNormal>
                <FormContent>
                    <div>
                        <input type="checkbox" id="lookingwoman" name="lookingmwoman" value="lookwoman" />
                        <label htmlFor="lookingwoamn"> a woman</label><br/>
                    </div>
                    
                    <div>
                        <input type="checkbox" id="lookingmman" name="lookingmman" value="lookman" />
                        <label htmlFor="lookingmman"> a man</label><br/>
                    </div>
                </FormContent>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstartertwo">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstarttwo">Back to start</button>
                </BackStart>
                <div id="modaloutalertstartertwo"></div>
                <div id="backdropoutalertstartertwo"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterTwo;