import styled from "styled-components";

export const DatingContainer = styled.div`
    border: 1px solid black;
    height:600px;
`;

export const FormScrollerDating = styled.form`
    width: 100%;
    height: 460px;
    overflow-y: scroll;
    scrollbar-color: rebeccapurple red;
    scrollbar-width: thin;
`;

export const TextDating = styled.span`
    width: 100%;
    font-size: 1.3rem;
    color:black;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
`;

export const WidgetDatingTable = styled.table`
    width:100%;
    border-spacing:20px;
`;

export const WidgetDatingTh = styled.th`
    text-align:left;
    color:#eb1b1b;
`;

export const WidgetDatingTd = styled.td`
    font-weight:300;
    color:#eb1b1b;
`;

export const TermsLabelDating = styled.span`
    width: 100%;
    font-size: 0.9rem;
    color:red;
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:600;
    margin:0;
`;