import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    //FormContent, 
    BackStart,
    FormResult,   
} from '../StarterStyles';

function StarterEight() {
    return ( 
        <StarterContainer style={FontEcran} id="startereight">
            <FormScroller>
                <br/>
                <TextNormal>Your dating match score</TextNormal>
                <br/>
                <FormResult>
                    <div style={{fontSize:'30px'}} id="datingresult">99%</div>
                </FormResult>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstartereight">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstarteight">Back to start</button>
                </BackStart>
                <div id="modaloutalertstartereight"></div>
                <div id="backdropoutalertstartereight"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterEight;