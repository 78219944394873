import React from "react-dom";
import logo from '../circle-heart.png';
import './landing.css';
import './pouchlanding';

function Landing() {
    return ( 
        <div id="landing">
            <div style={{landingStyle}} id="container">
                <div className="item">
                    <img src={logo} className="Land-logo" alt="logo" />
                </div>
                <div className="circle" style={{animationDelay:'-3s'}}></div>
                <div className="circle" style={{animationDelay:'-2s'}}></div>
                <div className="circle" style={{animationDelay:'-1s'}}></div>
                <div className="circle" style={{animationDelay:'0s'}}></div>
            </div>
        </div>
    );
}

const landingStyle = {
    height:'450px',
};

export default Landing;