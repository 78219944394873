import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    //TextTitle, 
    //TextContent, 
    ButtonStart,  
    FormScroller,
    TextNormal, 
    FormContent, 
    BackStart,  
} from '../StarterStyles';

function StarterSeven() {
    return ( 
        <StarterContainer style={FontEcran} id="starterseven">
            <FormScroller>
                <br/>
                <TextNormal>Your prefered contact</TextNormal>
                <div style={{textAlign:'center', fontWeight:'bolder', fontFamily:'cursive', fontSize:'21px'}}>
                    <label>You can choose only one</label>
                </div>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="texte" name="texte" value="texte" />
                        <label htmlFor="texte">Chat/letters</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="meeting" name="meeting" value="meeting" />
                        <label htmlFor="meeting">Meetings</label><br/>
                    </div>
                </FormContent>
                <br/>
                <FormContent>
                    <div>
                        <input type="checkbox" id="email" name="email" value="email" />
                        <label htmlFor="email">Email</label><br/>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div>
                        <input type="checkbox" id="calling" name="calling" value="calling" />
                        <label htmlFor="calling">Calling</label><br/>
                    </div>
                </FormContent>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterseven">Next</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartseven">Back to start</button>
                </BackStart>
                <div id="modaloutalertstarterseven"></div>
                <div id="backdropoutalertstarterseven"></div>
            </FormScroller>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterSeven;