import React from "react-dom";
import '../starter.css';
import '../pouchstarter';
import rishta from '../../ifti_3.jpg';

import { 
    StarterContainer, 
    FormScrollerOne,
    TextTitlePhone, 
    LargeInput,
    BackStart,
    ButtonStart, 
    TermsLabelPhone, 
} from '../StarterStyles';

function StarterPhone() {
    return ( 
        <StarterContainer style={FontEcran} id="starterphone">
            <FormScrollerOne>
                <br/>
                <TextTitlePhone>My phone number is</TextTitlePhone>
                <br/>
                <div style={{display:'block',textAlign:'center'}}>
                    <LargeInput type="tel" maxLength="11" id="phonenumber" placeholder='UK phone number(11 digits)' />
                </div>
                <br/>
                <TermsLabelPhone>
                    We will NOT make this public. Your valid phone <br/>
                    number is only used to signing and logging in.
                </TermsLabelPhone>
                <br/>
                <br/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <ButtonStart id="getstarterphone">Continue</ButtonStart>
                </div>
                <br/>
                <BackStart>
                    <button style={{fontWeight:'bold',fontSize:'17px'}} id="backstartphone">Back to start</button>
                </BackStart>
                <div id="modaloutstarterphone"></div>
                <div id="backdropoutstarterphone"></div>

                <div id="modaloutalertstarterphone"></div>
                <div id="backdropoutalertstarterphone"></div>
            </FormScrollerOne>
        </StarterContainer>
    );
}

const FontEcran = {
    backgroundImage:`url(${rishta})`,
    height: '600px',
    opacity: '0.6',
}

export default StarterPhone;